import Carousel from "./carousel";
import dawoodImg from "../images/team/dawood.png";
import KofiImg from "../images/team/kofi.png";
import CosmosImg from "../images/team/cosmos.png";
import MacbethImg from "../images/team/macbeth.png";
import GoodnessImg from "../images/team/goodness.png";

export const Team = () => {
	return (
		<div className="sm:w-4/5 w-[90%] mx-auto font-inter">
			<Carousel options={{}} hideDots buttomPosition="bottom">
				<div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
					<div className="flex lg:flex-row flex-col font-inter h-full">
						<div className="lg:w-1/2 w-full h-full overflow-hidden rounded-2xl">
							<img src={dawoodImg} alt="Dawood" className="rounded-2xl" />
						</div>
						<div className="lg:w-1/2 w-full flex flex-col space-y-3 justify-center rounded-2xl py-6 px-10 bg-white">
							<p>
								We started SeevCash in 2022 after seeing the gross difference in
								pricing between stores in Ghana. Products with no difference in
								size, function, or quality can be sold for double the price
								within the same area.
							</p>

							<p>
								Price is an African store’s biggest secret but at SeevCash,
								we’re exposing it to help you secure the hottest deals and make
								better purchasing decisions. We’re committed to providing you
								with the tools required to be street-smart.
							</p>
							<div className="pt-10">
								<p className="font-bold">Dawood Idris</p>
								<p>Cofounder and CEO</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
					<div className="flex lg:flex-row flex-col font-inter h-full">
						<div className="lg:w-1/2 w-full h-full overflow-hidden rounded-2xl">
							<img src={KofiImg} alt="Kofi" className="rounded-2xl" />
						</div>
						<div className="lg:w-1/2 w-full flex flex-col space-y-3 justify-center rounded-2xl py-6 px-10 bg-white">
							<p>
								We started SeevCash in 2022 after seeing the gross difference in
								pricing between stores in Ghana. Products with no difference in
								size, function, or quality can be sold for double the price
								within the same area.
							</p>

							<p>
								Price is an African store’s biggest secret but at SeevCash,
								we’re exposing it to help you secure the hottest deals and make
								better purchasing decisions. We’re committed to providing you
								with the tools required to be street-smart.
							</p>
							<div className="pt-10">
								<p className="font-bold">Charles Owusu</p>
								<p>Cofounder and CPO</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
					<div className="flex lg:flex-row flex-col font-inter h-full">
						<div className="lg:w-1/2 w-full h-full overflow-hidden rounded-2xl">
							<img src={CosmosImg} alt="Cosmos" className="rounded-2xl" />
						</div>
						<div className="lg:w-1/2 w-full flex flex-col space-y-3 justify-center rounded-2xl py-6 px-10 bg-white">
							<p>
								We started SeevCash in 2022 after seeing the gross difference in
								pricing between stores in Ghana. Products with no difference in
								size, function, or quality can be sold for double the price
								within the same area.
							</p>

							<p>
								Price is an African store’s biggest secret but at SeevCash,
								we’re exposing it to help you secure the hottest deals and make
								better purchasing decisions. We’re committed to providing you
								with the tools required to be street-smart.
							</p>
							<div className="pt-10">
								<p className="font-bold">Cosmos Appiah</p>
								<p>Cofounder and CTO</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
					<div className="flex lg:flex-row flex-col font-inter h-full">
						<div className="lg:w-1/2 w-full h-full overflow-hidden rounded-2xl">
							<img src={GoodnessImg} alt="Goodness" className="rounded-2xl" />
						</div>
						<div className="lg:w-1/2 w-full flex flex-col space-y-3 justify-center rounded-2xl py-6 px-10 bg-white">
							<p>
								We started SeevCash in 2022 after seeing the gross difference in
								pricing between stores in Ghana. Products with no difference in
								size, function, or quality can be sold for double the price
								within the same area.
							</p>

							<p>
								Price is an African store’s biggest secret but at SeevCash,
								we’re exposing it to help you secure the hottest deals and make
								better purchasing decisions. We’re committed to providing you
								with the tools required to be street-smart.
							</p>
							<div className="pt-10">
								<p className="font-bold">Goodness Olawoore</p>
								<p>Product Manager</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
					<div className="flex lg:flex-row flex-col font-inter h-full">
						<div className="lg:w-1/2 w-full h-full overflow-hidden rounded-2xl">
							<img src={MacbethImg} alt="Macbeth" className="rounded-2xl" />
						</div>
						<div className="lg:w-1/2 w-full flex flex-col space-y-3 justify-center rounded-2xl py-6 px-10 bg-white">
							<p>
								We started SeevCash in 2022 after seeing the gross difference in
								pricing between stores in Ghana. Products with no difference in
								size, function, or quality can be sold for double the price
								within the same area.
							</p>

							<p>
								Price is an African store’s biggest secret but at SeevCash,
								we’re exposing it to help you secure the hottest deals and make
								better purchasing decisions. We’re committed to providing you
								with the tools required to be street-smart.
							</p>
							<div className="pt-10">
								<p className="font-bold">Macbeth Asiamatieku</p>
								<p>Content Creator / Brand Expert</p>
							</div>
						</div>
					</div>
				</div>
			</Carousel>
		</div>
	);
};
