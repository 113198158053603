import { AboutCard } from "./components/about-card";
import { Navbar } from "./components/navbar";
import { Spacer } from "./components/spacer";

import findImg from "./images/find.png";
import trackImg from "./images/track.png";
import budgetImg from "./images/budget.png";
import artBg from "./images/art.png";
import artBgSm from "./images/art-sm.png";

import { Footer, FooterSm } from "./components/footer";
import { createContext, useContext, useState } from "react";
import SvgIcon1 from "./svgs/icon-1";
import SvgIcon2 from "./svgs/icon-2";
import SvgIcon3 from "./svgs/icon-3";
import { StepCard } from "./components/step-card";

import StepImg1 from "./images/step-1.png";
import StepImg2 from "./images/step-2.png";
import StepImg3 from "./images/step-3.png";
import { Customers } from "./components/customer";
import { Team } from "./components/team";
import SvgShield from "./svgs/shield";
import SvgScale from "./svgs/scale";
import SvgTime from "./svgs/time";
import marketPlaceImg from "./images/market.png";
import { Hero } from "./components/hero";
import { ActionButtons } from "./components/buttons";
import { FAQ } from "./components/faq";
import Carousel from "./components/carousel";
import SEO from "./components/seo";

export const AppContext = createContext({
  open: false,
  setOpen(open: boolean) {},
  yearRate: false,
  setYearRate(yearRate: boolean) {},
});

export const useAppCtxt = () => useContext(AppContext);

function App() {
  const [open, setOpen] = useState(false);
  const [yearRate, setYearRate] = useState(false);

  return (
    <AppContext.Provider value={{ open, setOpen, yearRate, setYearRate }}>
      <div className={open ? "h-screen overflow-hidden" : "h-screen"}>
        {/* SEO goes here*/}

        <SEO
          title="SeevCash | The Super App that saves you money"
          description="The all-in-one app that allows you to pay for items, track your expense and compare prices throughout your purchasing process. "
          name="SeevCash App"
          type="SeevCash"
        />

        <Navbar />

        <div className="bg-lightGrey">
          <Hero />

          <Spacer className="sm:h-40 h-20" />

          <section id="company" className="lg:w-[80%] w-[90%] mx-auto">
            <p className="font-rubik sm:text-4xl text-3xl text-center font-semibold w-full">
              SeevCash is for you if
            </p>

            <Spacer className="h-16" />

            <div className="w-fit lg:w-full mx-auto">
              <div className="flex lg:flex-row flex-col w-full justify-between lg:space-x-4 lg:space-y-0 space-y-12">
                <AboutCard
                  title="You run through money fast"
                  icon={<SvgIcon1 />}
                />
                <AboutCard
                  icon={<SvgIcon2 />}
                  title="You're always losing track of your expenses"
                />
                <AboutCard
                  icon={<SvgIcon3 />}
                  title="You've been cheated multiple times by local stores"
                />
              </div>
            </div>
          </section>

          <Spacer className="sm:h-40 h-20" />

          <div className="h-[calc(100vh*3)] relative">
            <div className="block right-0 h-full absolute">
              <img
                src={artBg}
                alt=""
                className="h-full lg:block hidden xl:object-contain object-cover right-0"
              />

              <img
                src={artBgSm}
                alt=""
                className="h-full block lg:hidden xl:object-contain object-cover right-0"
              />
            </div>

            <section
              id="features"
              className="mx-auto font-rubik h-1/3 bg-purple"
            >
              <Spacer className="md:h-40 h-20" />

              <div className="sm:w-[70%] w-[90%] flex flex-col md:space-y-0 space-y-10 lg:flex-row mx-auto items-center ">
                <div className="flex flex-col space-y-3 lg:w-1/2">
                  <p className="sm:text-5xl text-3xl md:text-left text-center text-white font-semibold">
                    Find cheaper prices across local stores
                  </p>

                  <p className="text-lightGrey font-inter md:text-left text-center">
                    Our price discovery feature is your friend as you go
                    shopping. Instead of wasting time going from shop to shop
                    haggling and looking for the best deals, ask SeevCash.
                  </p>
                </div>

                <div className="lg:w-3/4 z-20 w-full flex items-end md:justify-end justify-center">
                  <img src={findImg} alt="" className="md:h-[40rem]" />
                </div>
              </div>

              <Spacer className="sm:h-40 h-20" />
            </section>

            <section className="mx-auto font-rubik h-1/3 bg-lightGreen">
              <Spacer className="md:h-40 h-20" />

              <div className="sm:w-[70%] w-[90%] flex flex-col lg:space-y-0 space-y-10 lg:flex-row mx-auto items-center ">
                <div className="flex flex-col space-y-3 lg:w-1/2">
                  <p className="sm:text-5xl text-3xl md:text-left text-center text-white font-semibold">
                    Track your monthly expenses
                  </p>

                  <p className="text-lightGrey font-inter md:text-left text-center">
                    If you ever wonder where your money goes, Seevcash will find
                    out for you. With our expense tracking feature, you can
                    monitor and reduce your spending.
                  </p>
                </div>

                <div className="lg:w-3/4 w-full z-20 flex items-end md:justify-end justify-center">
                  <img src={trackImg} alt="" className="md:h-[40rem]" />
                </div>
              </div>

              <Spacer className="sm:h-40 h-20" />
            </section>

            <section className="mx-auto font-rubik h-1/3 bg-black-500">
              <Spacer className="md:h-40 h-20" />

              <div className="sm:w-[70%] w-[90%] flex flex-col lg:space-y-0 space-y-10 lg:flex-row mx-auto items-center ">
                <div className="flex flex-col space-y-3 lg:w-1/2">
                  <p className="sm:text-5xl text-3xl md:text-left text-center text-white font-semibold">
                    Set a budget in seconds
                  </p>

                  <p className="text-lightGrey font-inter md:text-left text-center">
                    Spending money is exciting but being broke is frustrating.
                    Pick the safe side by using SeevCash to build a budget in a
                    few clicks to avoid overspending.
                  </p>
                </div>

                <div className="lg:w-3/4 w-full z-20 flex items-end md:justify-end justify-center">
                  <img src={budgetImg} alt="" className="md:h-[40rem]" />
                </div>
              </div>

              <Spacer className="sm:h-40 h-20" />
            </section>
          </div>
          <Spacer className="h-36" />

          <section className="lg:w-[70%] w-[90%] relative flex items-center justify-center mx-auto font-rubik text-white">
            <div className="flex flex-col text-center items-center justify-center md:space-y-6 space-y-10 text-black-300 xl:w-2/3 w-full">
              <p className="md:text-5xl text-3xl font-medium">
                Money will come and go anyway. Wouldn’t it be nice to have more
                control over it?
              </p>
              <p className="md:w-3/4 mx-auto font-inter">
                SeevCash makes it possible for you to stop putting your money in
                the wrong places so you can start spending better. Our tools
                empower you to build your desired financial future.
              </p>

              <ActionButtons />
            </div>
          </section>

          <Spacer id="how-it-works" className="h-36" />

          <section className="mx-auto font-rubik bg-purple">
            <Spacer className="sm:h-40 h-20" />

            <div className="sm:w-[80%] w-[90%] mx-auto py-10">
              <h3 className="sm:text-5xl text-3xl text-white text-center font-semibold">
                Discover the Inner Workings of SeevCash: Unveiling the Mechanics
                Behind the App
              </h3>

              <Spacer className="sm:h-32 h-20" />

              <div className="lg:grid hidden lg:grid-cols-3 md:grid-cols-2 gap-8">
                <StepCard
                  image={StepImg1}
                  title="Step 1"
                  description="Our system automatically connects to your location to display products
					around your area to ease your decision-making process."
                />
                <StepCard
                  image={StepImg2}
                  title="Step 2"
                  description="We extract data from your expenses to help others determine the price of products without interfering with your privacy."
                />
                <StepCard
                  image={StepImg3}
                  title="Step 3"
                  description="We enable the contribute feature on SeevCash that allows you to snap a product and submit it to the system."
                />
              </div>

              <div className="lg:hidden block">
                <Carousel options={{}} hideDots buttomPosition="bottom">
                  <div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
                    <StepCard
                      image={StepImg1}
                      title="Step 1"
                      description="Our system automatically connects to your location to display products
					around your area to ease your decision-making process."
                    />
                  </div>
                  <div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
                    <StepCard
                      image={StepImg2}
                      title="Step 2"
                      description="We extract data from your expenses to help others determine the price of products without interfering with your privacy."
                    />
                  </div>
                  <div className="flex-[0_0_100%] md:w-1/3 w-full rounded-2xl items-center justify-center flex px-4 space-x-4">
                    <StepCard
                      image={StepImg3}
                      title="Step 3"
                      description="We enable the contribute feature on SeevCash that allows you to snap a product and submit it to the system."
                    />
                  </div>
                </Carousel>
              </div>
            </div>

            <Spacer className="sm:h-40 h-20" />
          </section>

          <Spacer className="h-36" />

          <section className="w-full py-20">
            <h3 className="sm:text-5xl px-12 font-rubik text-3xl sm:w-3/4 mx-auto text-black-300 text-center font-semibold">
              What people are saying about SeevCash
            </h3>

            <Spacer className="h-28" />

            <Customers />
          </section>

          <Spacer className="h-36" id="mission" />

          <section className="w-full py-20">
            <h3 className="sm:text-5xl font-rubik text-3xl sm:w-3/4 mx-auto px-12 text-black-300 text-center font-semibold">
              “To Help Users Save Money & Make Informed Decisions“
            </h3>

            <Spacer className="h-28" />

            <Team />
          </section>

          <Spacer className="h-36" />

          <section className="bg-purple">
            <Spacer className="sm:h-32 h-16" />

            <div className="sm:w-[80%] w-[90%] mx-auto text-white space-y-4">
              <p className="font-rubik sm:text-lg text-sm opacity-75 text-center w-full">
                Our values
              </p>

              <p className="font-rubik sm:text-4xl text-2xl text-center font-semibold w-full">
                What we believe in
              </p>

              <p className="font-rubik sm:text-lg text-sm opacity-75 text-center w-3/4 mx-auto">
                Our team and products are built to ensure that you have
                everything you need to do more with your money.
              </p>

              <Spacer className="h-16" />

              <div className="flex sm:flex-row flex-col w-full justify-between sm:space-x-4 sm:space-y-0 space-y-12">
                <AboutCard
                  title="Privacy"
                  icon={<SvgShield />}
                  description="Your data is safe with us"
                />
                <AboutCard
                  icon={<SvgScale />}
                  title="Scalability"
                  description="As your needs grow, so does the app"
                />
                <AboutCard
                  icon={<SvgTime />}
                  title="24/7 support"
                  description="Our entire team are waiting to hear from you"
                />
              </div>
            </div>

            <Spacer className="sm:h-32 h-16" />
          </section>

          <section>
            <Spacer className="sm:h-32 h-16" id="faqs" />

            <div className="md:w-3/4 lg:w-1/2 w-[90%] mx-auto text-white space-y-4">
              <FAQ />
            </div>

            <Spacer className="sm:h-32 h-16" />
          </section>

          <Spacer className="h-36" />

          <section className="sm:w-[80%] w-[90%] mx-auto flex sm:flex-row flex-col items-center justify-between lg:pt-0 pt-6 space-y-12 sm:space-y-0 lg:px-16 px-6 rounded-2xl bg-purple text-white ">
            <div className="space-y-4 py-4 sm:w-1/3">
              <p className="font-rubik sm:text-4xl text-2xl font-semibold w-full">
                Join the marketplace.
              </p>

              <p className="font-inter sm:text-lg text-sm opacity-75">
                13,000 users are slashing expenses with Seevcash. Do more with
                your Money!
              </p>

              <ActionButtons />
            </div>

            <div className="sm:w-1/2">
              <img src={marketPlaceImg} alt="" />
            </div>
          </section>

          <Spacer className="h-36" />

          <div
            id="contact"
            className="w-full sm:block hidden relative py-28 bg-black-500"
          >
            <Footer />
          </div>

          <div id="contact" className="w-full sm:hidden ">
            <FooterSm />
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
