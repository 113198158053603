import React, { ReactNode } from "react";

interface AboutCardProps {
	title: string;
	description?: string;
	icon: ReactNode;
}

export const AboutCard: React.FC<AboutCardProps> = ({
	title,
	description,
	icon,
}) => {
	return (
		<div
			className={`rounded-3xl w-full max-w-[26rem] bg-white text-black-300 drop-shadow-xl relative sm:px-7 px-4 py-8`}>
			<div className="flex flex-col justify-between items-center space-y-6">
				<div>{icon}</div>
				<p
					className={`font-rubik text-2xl font-semibold text-center max-w-[75%]`}>
					{title}
				</p>

				<p className="text-center">{description}</p>
			</div>
		</div>
	);
};
