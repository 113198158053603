import * as React from "react";

const SvgClose = (props: any) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={13}
		height={13}
		fill="none"
		{...props}>
		<rect
			width={15.137}
			height={1.211}
			x={1.912}
			y={0.953}
			fill="#fff"
			rx={0.605}
			transform="rotate(45.54 1.912 .953)"
		/>
		<rect
			width={15.137}
			height={1.211}
			x={0.756}
			y={11.832}
			fill="#fff"
			rx={0.605}
			transform="rotate(-41.144 .756 11.832)"
		/>
	</svg>
);

export default SvgClose;
