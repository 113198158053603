import AppleStore from "../images/apple.png";
import AndroidStore from "../images/android.png";

export function ActionButtons() {
	return (
		<div className="flex items-center space-x-2">
			<button>
				<a
					href="https://play.google.com/store/search?q=seevcash&c=apps"
					target="_blank"
					rel="noreferrer">
					<img src={AndroidStore} alt="android link" width={187} />
				</a>
			</button>
			<button>
				<a
					href="https://apps.apple.com/gh/app/seevcash/id6444502519"
					target="_blank"
					rel="noreferrer">
					<img src={AppleStore} alt="ios link" width={170} />
				</a>
			</button>
		</div>
	);
}
