import { Spacer } from "./spacer";
import { ActionButtons } from "./buttons";
// import manWithPhoneImg from "../images/hero/man-with-phone.png";
import ladyWithPhoneImg from "../images/hero/lady-with-phone.png";
import handWithPhoneImg from "../images/hero/hand-phone.png";
import Carousel from "./carousel";

function HeroSection({
  title,
  description,
  image,
  bgColor = "bg-purple",
}: {
  title: string;
  description: string;
  image: string;
  bgColor?: string;
}) {
  return (
    <div className="flex-[0_0_100%] w-full h-screen">
      <div className={`${bgColor} lg:pt-40  pt-40 w-full h-full`}>
        <div className="lg:pl-[10%] lg:w-full w-[90%] h-full mx-auto flex flex-col lg:flex-row relative">
          <div className="lg:w-1/2 h-full w-full flex flex-col justify-center">
            <p className="font-rubik font-semibold text-4xl md:text-7xl text-white">
              {title}
            </p>

            <Spacer className="h-7" />

            <p className="text-white font-inter sm:text-2xl opacity-80 text-base">
              {description}
            </p>

            <Spacer className="h-7" />

            <ActionButtons />
          </div>

          <div className="lg:w-1/2 flex h-full lg:absolute items-end justify-end lg:right-0">
            <img src={image} alt="hero" />
          </div>
        </div>
      </div>
    </div>
  );
}

export const Hero = () => {
  return (
    <div className="w-full font-inter">
      <Carousel
        options={{ loop: true, duration: 40 }}
        hideButtons
        auto
        dotPosition="bottom-10"
      >
        <HeroSection
          title="The smart shopper’s shield against rip-offs"
          description="The price of the same product can vary widely across stores but
					with SeevCash you can protect yourself from being cheated."
          image={handWithPhoneImg}
        />
        <HeroSection
          title="The Super App that saves you money"
          description="The all-in-one app that allows you to pay for items, track your expense and compare prices throughout your purchasing process."
          image={ladyWithPhoneImg}
          bgColor="bg-lightGreen"
        />
      </Carousel>
    </div>
  );
};
