import * as React from "react";
import { SVGProps } from "react";
const SvgShield = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={56}
		height={57}
		fill="none"
		{...props}>
		<path
			fill="#392095"
			d="M28 56.5c15.464 0 28-12.536 28-28S43.464.5 28 .5 0 13.036 0 28.5s12.536 28 28 28Z"
		/>
		<path
			fill="#E0E5EF"
			d="M37.351 12.53c.677.705 1.382 1.382 2.059 2.088.118.117.235.088.382.088h4.353c.265 0 .353.03.353.323 0 4.677.059 9.383-.03 14.06-.058 3.793-1.146 7.352-3.146 10.617-3 4.912-7.236 8.265-12.736 9.941-.088.03-.205 0-.235.118h-.353v-.06c.03-.558.03-1.117.03-1.705 0-.147 0-.324.058-.47.03-.089.118-.118.177-.177.823-.324 1.676-.618 2.5-1a19.107 19.107 0 0 0 4.588-3.03c2.677-2.323 4.588-5.205 5.765-8.558.53-1.56.882-3.147 1-4.794.117-1.383.059-2.736.059-4.089V17.56c0-.559 0-.559-.53-.559h-2.617c-.353 0-.618-.118-.883-.353-.47-.5-.97-.941-1.441-1.441a1.242 1.242 0 0 0-.941-.383h-7.059c-.206 0-.412.03-.618-.088a.406.406 0 0 1-.088-.117c-.117-.618-.059-1.236-.03-1.853 0-.088.06-.177.118-.265 3.118.03 6.206.03 9.265.03Z"
		/>
		<path
			fill="#F2F4F8"
			d="M28.146 47.5v1.823c0 .148.059.294-.147.353a17.257 17.257 0 0 1-3.618-1.353c-5.823-2.911-9.706-7.47-11.647-13.676-.647-2.059-.941-4.176-.941-6.324V15.06c0-.294.088-.353.353-.353h4.323c.236 0 .412-.06.56-.236.617-.676 1.293-1.235 1.882-1.94h9.235v2.176c-.147.176-.382.147-.588.147h-6.97c-.472 0-.795.117-1.119.47-.44.471-.911.942-1.382 1.383-.235.235-.47.323-.823.323h-2.736c-.44 0-.47.03-.47.47 0 3.824-.06 7.677.03 11.5.058 3.119.91 6.06 2.41 8.824a19.733 19.733 0 0 0 3.383 4.56c2.177 2.176 4.706 3.793 7.618 4.764.235.059.53.059.647.353Z"
		/>
		<path
			fill="#422BB2"
			d="M28.145 47.5c-1.176-.412-2.353-.853-3.441-1.442-5.147-2.794-8.47-7.03-10.059-12.647a18.76 18.76 0 0 1-.676-5.088V17.264c0-.323.059-.412.382-.412 1.03.03 2.03 0 3.059 0a.645.645 0 0 0 .5-.206c.559-.588 1.147-1.147 1.706-1.735.176-.176.353-.264.588-.264h7.941v.029c.059.059.118.118.118.206.03.647.088 1.264-.03 1.882-.117.177-.294.177-.47.177-1.824.176-3.177 1.088-4.147 2.588-.5.794-.735 1.647-.765 2.588-.03 1 0 1.97 0 2.97 0 .177.03.383-.117.56-.177.117-.383.088-.56.088h-1.146c-.236 0-.353.117-.353.353v7.088a7.514 7.514 0 0 0 6.617 7.5c.324.03.677-.059.941.206.118.176.089.412.089.588v5.824c-.088.147-.147.176-.177.205Z"
		/>
		<path
			fill="#392095"
			d="M28.146 16.882v-2.147c2.383 0 4.736.03 7.118-.03.853-.029 1.47.177 2 .854.383.5.824 1.059 1.353 1.294.559.235 1.265.059 1.912.059.47 0 .97.03 1.441 0 .294-.03.353.088.353.353v9.53c0 1.293.03 2.587-.118 3.881-.294 2.412-.97 4.736-2.117 6.883-2.56 4.823-6.412 8.117-11.589 9.882-.058.03-.117.03-.176.03-.206-.03-.147-.206-.147-.324V41c0-.03.03-.03.03-.059.323-.147.676-.088 1-.147 3.47-.5 6.146-3.323 6.44-6.794V26.382c0-.559 0-.559-.558-.559h-1.06c-.146 0-.323 0-.47-.088-.147-.147-.117-.353-.117-.559v-2.823c0-2.794-2.089-5.088-4.883-5.324-.147 0-.294 0-.412-.088v-.059Z"
		/>
		<path
			fill="#25C025"
			d="M28.145 40.94c-3.176-.147-5.53-1.588-6.941-4.47a7.114 7.114 0 0 1-.706-3.118v-7.294c0-.294.059-.412.382-.382.618.03 1.206 0 1.824 0a.591.591 0 0 1 .382-.118c.618 0 1.206-.03 1.824.03.97-.06 1.941-.03 2.912-.03a.59.59 0 0 1 .382.118c.147.147.118.353.118.559v3.294c0 .176 0 .353-.118.53-.177.176-.382.146-.588.146-.383.03-.412.03-.412.441v5.441c0 .295.088.412.382.412.206 0 .412-.03.588.118.118.147.118.324.118.47v3.353c0 .177 0 .353-.147.5Z"
		/>
		<path
			fill="#F2F4F8"
			d="M24.852 25.648h-2.176c0-1.03.03-2.03 0-3.06-.118-3.087 2.265-5.705 5.47-5.735v.03c.06.059.118.118.118.176.03.647.118 1.294-.03 1.942-.117.176-.293.176-.44.205-1.56.206-2.824 1.471-2.765 3.412.03.853 0 1.706 0 2.53-.059.176-.03.353-.177.5Z"
		/>
		<path
			fill="#25C025"
			d="M28.147 30.029v-4.324c0-.03.029-.03.029-.058a.568.568 0 0 1 .294-.089c.97 0 1.941-.03 2.912 0 .588-.059 1.206-.03 1.823-.03.148 0 .265.03.383.119.617 0 1.264.029 1.882 0 .235 0 .294.088.294.294 0 2.588.088 5.206-.03 7.794-.146 3-1.735 5.176-4.47 6.5-.97.47-2.059.706-3.147.676v-4.323c0-.03.03-.03.03-.06.147-.087.294-.087.44-.087.442-.03.442-.03.471-.5V30.676c0-.5 0-.5-.5-.53-.117 0-.235 0-.353-.058a.063.063 0 0 1-.058-.06Z"
		/>
		<path
			fill="#E0E5EF"
			d="M33.588 25.646H31.41c-.147-.147-.117-.323-.117-.529v-2.765c-.03-1.53-1-2.764-2.412-3.088-.206-.059-.441-.059-.647-.147l-.088-.088v-2.147c2.558-.118 5.029 1.97 5.382 4.5.117.882.03 1.735.059 2.617v1.647Z"
		/>
		<path
			fill="#422BB2"
			d="M24.852 25.647v-3.059c0-2.117 1.176-3.382 3.264-3.559v.03c.147.147.147.323.147.5v5.588c0 .176 0 .382-.147.5v.03c-1.088 0-2.176 0-3.264-.03ZM28.147 36.588c-.294 0-.588-.03-.882 0-.177 0-.236-.059-.236-.235v-6.088c0-.177.06-.236.236-.236h.882v.03c.147.147.147.323.147.5v5.5c-.03.176 0 .353-.147.53Z"
		/>
		<path
			fill="#392095"
			d="M28.117 25.647v-6.588c1.853.029 3.235 1.44 3.265 3.323v3.265h-3.265ZM28.117 36.558v-6.5c.353-.03.794-.147 1.03.03.235.176.058.646.058.97v5.176c0 .265-.058.383-.323.353-.235-.03-.5-.03-.765-.03Z"
		/>
	</svg>
);
export default SvgShield;
