import React from "react";
import Carousel from "./carousel";
import abdulImg from "../images/customers/abdul.png";
import danielImg from "../images/customers/daniel.png";
import beatriceImg from "../images/customers/beatrice.png";
export const messages = [
	{
		name: "Abdul Basit",
		title: "Broken Glass",
		image: abdulImg,
		message:
			"I used to spend recklessly but now I’m very careful and my saving skill has become even better. When I go grocery shopping, SeevCash is my friend",
	},
	{
		name: "Daniel Umoren",
		title: "Recent Graduate",
		image: danielImg,
		message:
			"Using SeevCash has been amazing; it has helped me track and manage my finances so well.",
	},
	{
		name: "Beatrice Blay,  R&D Technologist.",
		title: "Promasidor Ghana LTD",
		image: beatriceImg,
		message:
			"It was part of my plans to consistently track my expenses this year, and you have made it easier.",
	},
];
const CustomerCard = ({
	message,
	title,
	name,
	image,
}: {
	message: string;
	name: string;
	title: string;
	image: any;
}) => {
	return (
		<div className="bg-white py-5 px-8 font-inter sm:w-96 w-full h-[15rem] flex flex-col justify-between rounded-xl">
			<p>"{message}"</p>

			<div className="flex items-center space-x-4">
				<div className="w-14 h-14">
					<img src={image} alt={`${name}'s image`} />
				</div>
				<div className="flex flex-col justify-between">
					<p className="font-bold">{name}</p>
					<p>{title}</p>
				</div>
			</div>
		</div>
	);
};

export const Customers = () => {
	return (
		<div className="sm:w-4/5 w-[90%] mx-auto">
			<Carousel options={{}}>
				{messages.map((message, index: number) => (
					<div
						className="lg:flex-[0_0_30%] md:flex-[0_0_50%] flex-[0_0_100%] w-full rounded-2xl items-center justify-center flex px-4 space-x-4"
						key={index}>
						<CustomerCard {...message} />
					</div>
				))}
			</Carousel>
		</div>
	);
};
