import * as React from "react";

const SvgBurger = (props: any) => (
	<svg
		width="16"
		height="11"
		viewBox="0 0 16 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="0.365234"
			y="0.508789"
			width="15.1367"
			height="1.21093"
			rx="0.605467"
			fill="white"
		/>
		<rect
			x="0.365234"
			y="4.94922"
			width="15.1367"
			height="1.21093"
			rx="0.605467"
			fill="white"
		/>
		<rect
			x="0.365234"
			y="9.38965"
			width="15.1367"
			height="1.21093"
			rx="0.605467"
			fill="white"
		/>
	</svg>
);

export default SvgBurger;
