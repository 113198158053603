import {
	useState,
	useEffect,
	useCallback,
	ReactNode,
	PropsWithChildren,
} from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

type PropType = PropsWithChildren<
	React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
>;
export const DotButton: React.FC<PropType> = props => {
	const { children, ...restProps } = props;

	return (
		<button
			type="button"
			className="bg-blue-600 w-2 h-2 mx-3 rounded-full"
			{...restProps}>
			{children}
		</button>
	);
};

export const PrevButton = (props: any) => {
	const { children, bottom, ...restProps } = props;

	return (
		<button
			className={`w-12 h-12 bg-black-300 text-danger disabled:opacity-40 justify-center items-center rounded-full absolute ${
				bottom
					? "-bottom-20 right-1/2 flex"
					: "-left-10 top-[40%] sm:flex hidden"
			}`}
			type="button"
			{...restProps}>
			<svg
				width="16"
				height="17"
				viewBox="0 0 16 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16 7.5L3.83 7.5L9.42 1.91L8 0.5L0 8.5L8 16.5L9.41 15.09L3.83 9.5L16 9.5V7.5Z"
					fill="white"
				/>
			</svg>
		</button>
	);
};

export const NextButton = (props: any) => {
	const { children, bottom, ...restProps } = props;

	return (
		<button
			className={`w-12 h-12 bg-black-300 text-danger rounded-full disabled:opacity-40  justify-center items-center absolute ${
				bottom
					? "-bottom-20 left-1/2 flex"
					: "-right-10 top-[40%] sm:flex hidden"
			}`}
			type="button"
			{...restProps}>
			<svg
				width="16"
				height="17"
				viewBox="0 0 16 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.11959e-07 9.5L12.17 9.5L6.58 15.09L8 16.5L16 8.5L8 0.499999L6.59 1.91L12.17 7.5L7.86805e-07 7.5L6.11959e-07 9.5Z"
					fill="white"
				/>
			</svg>
		</button>
	);
};

const Carousel = (props: {
	options?: object;
	children: ReactNode;
	hideButtons?: boolean;
	auto?: boolean;
	dotPosition?: string;
	hideDots?: boolean;
	buttomPosition?: "bottom" | "sides";
}) => {
	const {
		options,
		children,
		hideButtons,
		auto,
		dotPosition,
		hideDots,
		buttomPosition = "sides",
	} = props;
	const autoOptions = auto ? [Autoplay()] : [];
	const [emblaRef, emblaApi] = useEmblaCarousel(options, autoOptions);
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

	const scrollPrev = useCallback(
		() => emblaApi && emblaApi.scrollPrev(),
		[emblaApi]
	);
	const scrollNext = useCallback(
		() => emblaApi && emblaApi.scrollNext(),
		[emblaApi]
	);
	const scrollTo = useCallback(
		(index: number) => emblaApi && emblaApi.scrollTo(index),
		[emblaApi]
	);

	const onInit = useCallback((emblaApi: any) => {
		setScrollSnaps(emblaApi.scrollSnapList());
	}, []);

	const onSelect = useCallback((emblaApi: any) => {
		setSelectedIndex(emblaApi.selectedScrollSnap());
		setPrevBtnDisabled(!emblaApi.canScrollPrev());
		setNextBtnDisabled(!emblaApi.canScrollNext());
	}, []);

	useEffect(() => {
		if (!emblaApi) return;

		onInit(emblaApi);
		onSelect(emblaApi);
		emblaApi.on("reInit", onInit);
		emblaApi.on("reInit", onSelect);
		emblaApi.on("select", onSelect);
	}, [emblaApi, onInit, onSelect]);

	return (
		<div className="relative">
			<div className="overflow-hidden" ref={emblaRef}>
				<div className="flex w-full justify-between">{children}</div>
				{hideButtons ? null : (
					<div className="flex items-center justify-center space-x-4">
						<PrevButton
							onClick={scrollPrev}
							bottom={buttomPosition === "bottom"}
							disabled={prevBtnDisabled}
						/>
						<NextButton
							onClick={scrollNext}
							bottom={buttomPosition === "bottom"}
							disabled={nextBtnDisabled}
						/>
					</div>
				)}
			</div>
			{hideDots ? null : (
				<div
					className={`absolute left-0 right-0 flex items-center justify-center ${
						scrollSnaps.length === 1 ? "hidden" : ""
					} ${dotPosition ? dotPosition : "-bottom-16"}`}>
					{scrollSnaps.map((_, index) => (
						<DotButton
							key={index}
							onClick={() => scrollTo(index)}
							className={`w-3 h-3 mx-3 flex items-center after:rounded-full after:bg-black-200 after:w-full after:h-full after:content-[''] ${
								index === selectedIndex ? "" : "opacity-25"
							}`}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default Carousel;
