import { Disclosure } from "@headlessui/react";

import { ReactNode } from "react";

const FaqData: { question: string; answer: string | ReactNode }[] = [
	{
		question:
			"What is SeevCash, and how does it help users manage their money?",
		answer:
			"SeevCash is a money management platform designed to assist users in recording and tracking their spending, creating personalised budgets, and finding affordable products based on their preferred location.",
	},
	// {
	// 	question: "How does Kotani Pay work?",
	// 	answer: (
	// 		<div>
	// 			<p>Our on-ramp/off services works in three ways</p>
	// 			<ul className="list-decimal list-inside space-y-4 mt-4">
	// 				<li>
	// 					API- integrate with our API and access off-ramp services to local
	// 					currencies across Africa,{" "}
	// 					<a
	// 						href="https://docs.kotanipay.com/"
	// 						target="_blank"
	// 						className="underline">
	// 						Read documentation
	// 					</a>
	// 					.
	// 				</li>
	// 				<li>
	// 					Stablecoin Settlement Solution- Access USD through our
	// 					Over-the-Counter (OTC) service that provides dollar-pegged
	// 					stablecoins.
	// 				</li>
	// 				<li>
	// 					SMS wallet- Access fully-functional blockchain wallet through an SMS
	// 					interface without need for internet.
	// 				</li>
	// 			</ul>
	// 		</div>
	// 	),
	// },
	{
		question:
			"Can I use SeevCash to create and track a personalised budget for my expenses?",
		answer:
			"Yes, SeevCash allows users to create and track personalised budgets, helping them better manage their expenses. SeevCash provides tools and features to record and track spending habits, offering users a comprehensive overview of their financial activities",
	},
	{
		question:
			"Is SeevCash only limited to tracking spending, or does it offer additional money management features?",
		answer:
			"  In addition to tracking spending, SeevCash offers a range of money management features, including budget creation and finding affordable products.",
	},
	{
		question:
			"Can I find affordable products using SeevCash, and how does it determine affordability?",
		answer:
			"Yes, SeevCash allows users to find affordable products based on their preferred location, utilizing criteria to determine affordability.",
	},
	{
		question:
			"What information does SeevCash use to locate affordable products based on my preferred location?",
		answer:
			"SeevCash uses user preferences and location data to identify affordable products available in the specified area",
	},
	{
		question:
			"How secure is the personal financial data stored and managed within the SeevCash platform?",
		answer:
			"SeevCash prioritizes the security of personal financial data, employing robust measures to ensure the confidentiality and integrity of user information.",
	},
	{
		question:
			"Can businesses integrate their products into the SeevCash platform to reach potential customers?",
		answer:
			"Details about product integration would depend on SeevCash's specific features and partnerships. It's recommended to check with SeevCash for the most accurate information.",
	},
	{
		question:
			"Does SeevCash offer any tools or insights to help users make informed financial decisions?",
		answer:
			"Yes, SeevCash provides tools and insights to assist users in making informed financial decisions, empowering them to manage their money effectively.",
	},
	{
		question:
			"How user-friendly is the SeevCash interface for both beginners and experienced users in money management?",
		answer:
			"SeevCash is designed to be user-friendly, catering to both beginners and experienced users in the realm of money management, ensuring a seamless experience for all.",
	},
];

function FaqItem({
	question,
	answer,
}: {
	question: string;
	answer: string | ReactNode;
}) {
	return (
		<Disclosure>
			{({ open }) => (
				<div className="bg-purple rounded-lg shadow-lg p-4">
					<Disclosure.Button className="flex w-full text-white items-start justify-between text-left text-sm font-medium">
						<span className="font-semibold md:text-lg text-sm mr-2">
							{question}
						</span>

						<span
							className={`transform bg-danger rounded-full ${
								open ? "-rotate-90" : "rotate-90"
							}`}>
							<svg
								className="sm:w-4 w-3 sm:h-4 h-3 text-kp-grey-950"
								viewBox="0 0 532 532">
								<path
									fill="currentColor"
									d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
								/>
							</svg>
						</span>
					</Disclosure.Button>
					<Disclosure.Panel className="sm:px-12 px-2 opacity-70 text-white py-3 sm:text-base text-xs">
						{answer}
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	);
}
export function FAQ() {
	return (
		<div className="flex flex-col justify-center">
			<h3 className="sm:text-5xl font-rubik text-3xl  mx-auto text-black-300 text-center font-semibold">
				Some common questions
			</h3>

			<div className="mx-auto w-full flex-col space-y-4 mt-20">
				{FaqData.map(({ question, answer }, idx) => {
					return <FaqItem key={idx} question={question} answer={answer} />;
				})}
			</div>
		</div>
	);
}
