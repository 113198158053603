import "./index.css";
import { router } from "./route";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Suspense
			fallback={
				<div className="h-screen flex items-center justify-center">
					<h1>LOADING ..... </h1>
				</div>
			}>
			<RouterProvider router={router} />
		</Suspense>
	</React.StrictMode>
);
