import App from "./App";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "/privacy",
		element: <Privacy />,
	},
	{
		path: "/terms",
		element: <Terms />,
	},
]);
