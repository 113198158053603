import Logo from "../images/logo.png";
import { Spacer } from "./spacer";
import footerImg from "../images/footer.png";
import { Link, useLocation } from "react-router-dom";

export const Footer = () => {
	const { pathname } = useLocation();
	return (
		<div className="w-[75%] mx-auto font-rubik flex justify-between">
			<div className="flex flex-col space-y-4">
				<h1 className="font-bold text-4xl text-lightGrey">SeevCash</h1>

				<div className="flex flex-col space-y-4">
					<div className="text-grey flex flex-col space-y-1 text-xs">
						<p className="text-left">447 Bdwy 2nd Fl Ste 618</p>
						<p className="text-left">New York UY 10013, USA</p>
						<p className="text-left">0209111659</p>
					</div>
				</div>

				<p className="text-grey text-xs">
					© {new Date().getFullYear()} SeevCash Inc.
				</p>
			</div>

			<div className="flex ml-auto space-x-10">
				{pathname === "/" ? (
					<div className="text-grey flex flex-col space-y-4 text-xs">
						<button className="text-left">
							<a href="#features">Features</a>
						</button>
						<button className="text-left">
							<a href="#how-it-works">How it works</a>
						</button>
						<button className="text-left">
							<a href="#mission">Mission</a>
						</button>
						<button className="text-left">
							<a href="#faqs">FAQs</a>
						</button>
					</div>
				) : null}

				<div className="flex flex-col space-y-4 text-grey text-xs">
					<button className="text-left">
						<Link to="/privacy">Privacy policy</Link>
					</button>
					<button className="text-left">
						<Link to="/terms">Terms of Service</Link>
					</button>
				</div>
			</div>

			<div className="absolute bottom-0 right-0 left-0 flex items-center justify-center w-full">
				<img src={footerImg} alt="" className="w-2/5" />
			</div>
		</div>
	);
};

export const FooterSm = () => {
	const { pathname } = useLocation();
	return (
		<div className="w-full font-rubik flex flex-col items-center justify-center py-20 px-8">
			<img src={Logo} alt="" width={169} height={48} />

			<Spacer className="h-5" />

			<div className="flex space-x-2 text-grey">
				<button className="text-left">
					<Link to="/privacy">Privacy policy</Link>
				</button>
				<button className="text-left">
					<Link to="/terms">Terms of Service</Link>
				</button>
			</div>

			<Spacer className="h-5" />

			<div className="flex flex-col space-y-4">
				<div className="text-grey flex flex-col space-y-2 justify-center items-center">
					<p>447 Bdwy 2nd Fl Ste 618</p>
					<p>New York UY 10013, USA</p>
					<p>0209111659</p>
				</div>
			</div>

			<Spacer className="h-8 w-full" />

			{pathname === "/" ? (
				<div className="text-grey flex items-center justify-between w-full">
					<button className="text-left">
						<a href="#features">Features</a>
					</button>
					<button className="text-left">
						<a href="#how-it-works">How it works</a>
					</button>
					<button className="text-left">
						<a href="#mission">Mission</a>
					</button>
					<button className="text-left">
						<a href="#faqs">FAQs</a>
					</button>
				</div>
			) : null}

			<Spacer className="h-8 w-full" />

			<p className="text-grey text-xs">
				© {new Date().getFullYear()} SeevCash Inc. All rights reserved.
			</p>
		</div>
	);
};
