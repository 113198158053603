import * as React from "react";
import { SVGProps } from "react";
const SvgIcon2 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={56}
		height={56}
		fill="none"
		{...props}>
		<path
			fill="#312783"
			d="M20.661 34.515c3.258 1.438 6.663 1.703 10.156 1.38 1.408-.147 2.788-.5 4.343-.763-2.641-.264-5.077-.352-7.484-.734-2.583-.44-5.019-1.409-6.75-3.58a.838.838 0 0 0-.44-.294c-1.028-.264-2.055-.47-3.082-.705-.47.88-.353 1.673.176 2.407.733 1.115 1.878 1.761 3.081 2.29Z"
		/>
		<path
			fill="#312783"
			d="M28.498 39.064c-3.962 0-7.777-.675-11.006-3.199-.646 1.027-.47 1.967.264 2.76.616.645 1.32 1.29 2.084 1.672 4.843 2.407 9.862 2.495 14.969 1.027 1.643-.47 3.17-1.232 4.343-2.524.91-.998.969-1.76.353-2.935-3.288 2.495-7.044 3.2-11.007 3.2ZM27.941 27.442c.528-.088 1.057-.176 1.556-.323.47-.117.939-.264 1.291-.381-2.172-.147-4.402-.206-6.604-.5-2.318-.322-4.52-1.115-6.398-2.67-1.086-.91-1.79-2.025-1.702-3.522.029-.294-.03-.558-.059-.851-.088-.06-.147-.088-.235-.147-.704.558-1.438 1.086-2.054 1.702-1.086 1.116-1.116 2.407 0 3.463.939.852 2.025 1.644 3.199 2.143 3.551 1.497 7.25 1.614 11.006 1.086Z"
		/>
		<path
			fill="#312783"
			d="M20.78 22.07c2.407 1.146 5.02 1.498 7.72 1.615 1.643-.205 3.287-.352 4.871-.675 2.143-.44 4.197-1.203 5.753-2.876 1.086-1.174 1.145-2.436 0-3.522-.763-.734-1.644-1.409-2.583-1.82-4.784-2.113-9.685-2.084-14.645-.587-1.527.47-2.906 1.204-3.992 2.377-1.057 1.174-1.145 2.378-.03 3.464.852.822 1.85 1.526 2.906 2.025Zm9.363-2.7c-1.35-.293-2.73-.498-4.08-.792-.587-.117-1.232-.235-1.731-.528-.411-.235-.94-.734-.94-1.115 0-.411.44-.998.852-1.204.792-.352 1.643-.557 2.494-.675.5-.088.88-.146.852-.792h1.82c-.06.704.41.734.91.792 1.202.118 2.347.382 3.374 1.38-.88.146-1.643.293-2.406.41-.147.03-.382-.058-.5-.146-.91-.734-1.966-.704-3.052-.646a.791.791 0 0 0-.44.147c-.206.147-.352.352-.528.528.205.147.381.411.587.44.998.235 2.025.382 3.052.587.91.206 1.82.382 2.7.675.558.177 1.086.529 1.086 1.233 0 .705-.499 1.057-1.056 1.262-.793.294-1.615.47-2.436.675-.382.088-.793.088-1.204.147-.03.294-.058.558-.117.88h-1.761c-.059-.264-.118-.528-.176-.85-1.615-.294-3.405-.235-4.667-1.85.998-.117 1.878-.234 2.73-.293.117 0 .293.088.41.176 1.292 1.174 2.789.91 4.286.616.205-.029.47-.352.47-.557 0-.176-.324-.44-.53-.5Z"
		/>
		<path
			fill="#312783"
			d="M28 0C12.533 0 0 12.533 0 28c0 15.468 12.533 28 28 28 15.468 0 28-12.532 28-28C56 12.533 43.468 0 28 0Zm17.023 29.38c-.205 1.144-.969 1.995-1.878 2.7-.411.323-.793.675-1.262.822-.94.293-1.174 1.085-1.497 1.82-.088.175.03.44.088.645.998 3.199.704 5.9-2.172 7.602-2.436 1.438-5.107 2.113-7.895 2.289-3.405.235-6.75-.03-9.92-1.409a12.337 12.337 0 0 1-2.349-1.32c-1.35-.97-2.172-2.202-2.025-3.992.088-1.116-.146-2.29.529-3.346.088-.118.058-.352-.03-.5-.822-1.613-.528-3.316-.323-4.989 0-.117.03-.205.03-.205-1.028-.704-2.055-1.35-2.965-2.084-.998-.822-1.291-1.937-1.438-3.229-.323-3.023 1.115-4.813 3.64-6.075.234-.117.528-.323.587-.558.498-1.496 1.584-2.406 2.876-3.17 2.29-1.35 4.843-1.966 7.455-2.142 3.404-.235 6.75.03 9.95 1.38.88.381 1.731.85 2.494 1.379 1.321.94 2.143 2.172 1.996 3.903-.088.969 0 1.938 0 2.994.851.587 1.85 1.174 2.759 1.937.968.822 1.497 1.908 1.409 3.229-.059.763.059 1.555-.059 2.319Z"
		/>
		<path
			fill="#312783"
			d="M40.68 23.069c-.381-.264-.704-.147-1.056.117-.734.587-1.556 1.086-2.348 1.644-.206.147-.5.293-.558.528-.763 2.143-2.436 3.287-4.403 3.962-1.966.675-3.991 1.116-5.958 1.615.118.029.206.088.323.117 3.992 1.115 7.983 1.115 11.946 0 1.79-.499 3.463-1.233 4.725-2.67 1.027-1.175 1.057-2.408-.088-3.464-.763-.734-1.702-1.233-2.583-1.85Z"
		/>
	</svg>
);
export default SvgIcon2;
