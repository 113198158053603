import { Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import Logo from "../images/logo.png";
import SvgBurger from "../svgs/burger";
import SvgClose from "../svgs/close";
import { AppContext } from "../App";
import { isIOS, isMacOs } from "react-device-detect";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const getDownloadLink = () => {
	if (isIOS || isMacOs) {
		// iOS (iPhone, iPad, iPod)
		return "https://apps.apple.com/gh/app/seevcash/id6444502519";
	}

	// Android
	return "https://play.google.com/store/search?q=seevcash&c=apps";
};

export const NavItem: React.FC<{
	link: string;
	name: string | JSX.Element;
	close?: (open: boolean) => void;
}> = ({ link, name, close }) => {
	const isActive = link === window.location.hash.split("#")[1];

	return (
		<a
			href={`#${link}`}
			onClick={() => close?.(false)}
			className={
				isActive ? "font-rubik capitalize text-purple" : "font-rubik capitalize"
			}>
			{name}
		</a>
	);
};

export const Navbar = () => {
	const { setOpen, open } = useContext(AppContext);
	const { pathname } = useLocation();

	return (
		<>
			<div className="flex items-center lg:w-[80%] w-[90%] fixed z-50 right-0 left-0 bg-white rounded-full mt-12 mx-auto lg:h-20 h-16 lg:px-10 px-5 drop-shadow-[0_16px_35px_rgba(0,0,0,0.25)]">
				<Link to="/" className="mr-auto">
					<img src={Logo} alt="Logo" className="xl:w-3/4 lg:w-40 w-32" />
				</Link>
				<div className="lg:flex hidden items-center justify-between space-x-5">
					{pathname === "/" ? (
						<>
							<NavItem name="Features" link="features" close={setOpen} />
							<NavItem
								name="How it works"
								link="how-it-works"
								close={setOpen}
							/>
							<NavItem name="Mission" link={"mission"} close={setOpen} />
							<NavItem name="FAQs" link={"faqs"} close={setOpen} />
						</>
					) : null}

					<button className="bg-black-500 py-2 px-5 rounded-full text-lg text-white">
						<a href={getDownloadLink()} target="_blank" rel="noreferrer">
							Get SeevCash
						</a>
					</button>
				</div>

				<div className="lg:hidden block space-x-2 font-inter">
					<button className="bg-black-500 p-2 rounded-full text-xs text-white">
						<a href={getDownloadLink()} target="_blank" rel="noreferrer">
							Get SeevCash
						</a>
					</button>
					{pathname === "/" ? (
						open ? (
							<button
								className="bg-black-500 p-3 rounded-full"
								onClick={() => setOpen(!open)}>
								<SvgClose />
							</button>
						) : (
							<button
								className="bg-black-500 p-3 rounded-full"
								onClick={() => setOpen(!open)}>
								<SvgBurger />
							</button>
						)
					) : null}
				</div>

				<div>
					<Transition
						as={Fragment}
						show={open}
						enter=" transition transform ease-out duration-75"
						enterFrom="translate-y-0"
						enterTo="translate-y-100"
						leave="transition-opacity ease-in duration-150"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="lg:hidden absolute right-0 left-0 bg-white top-[130%] rounded-2xl flex flex-col p-6 space-y-6">
							<NavItem name="Features" link="features" close={setOpen} />
							<NavItem
								name="How it works"
								link="how-it-works"
								close={setOpen}
							/>
							<NavItem name="Mission" link={"mission"} close={setOpen} />
							<NavItem name="FAQs" link={"faqs"} close={setOpen} />
						</div>
					</Transition>
				</div>
			</div>
		</>
	);
};
