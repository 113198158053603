interface StepCardProps {
	image: any;
	title: string;
	description: string;
}
export const StepCard = ({ image, title, description }: StepCardProps) => {
	return (
		<div className="text-black-300 bg-white rounded-2xl  place-self-center overflow-hidden max-w-[26rem] h-[37rem]">
			<div className="bg-lightPurple rounded-2xl h-2/3 flex items-end">
				<img src={image} alt={`${title}`} className="w-3/4 mx-auto" />
			</div>

			<div className="flex flex-col py-8 px-4 space-y-4 h-1/3">
				<h4 className="font-rubik font-semibold">{title}</h4>

				<p className="font-inter">{description}</p>
			</div>
		</div>
	);
};
